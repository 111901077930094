import { styled, css } from "styled-components";
import theme from "styled-theming";

import IconBase from "components/IconBase";

export const Logo = styled.img`
    height: 3rem;
    width: auto;
`;

export const StyledIcon = styled(IconBase)`
    vertical-align: middle;
    ${theme("theme", {
        image: css`
            filter: brightness(0) saturate(100%) invert(100%);
        `,
        black: css`
            filter: brightness(0) saturate(100%) invert(100%);
        `,
    })};
`;
